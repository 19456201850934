import { call, delay } from 'redux-saga/effects';

import { SignupApi } from '../../infrastructure/api';
import { SagaStatus } from '../../infrastructure/api/types/signup/SagaStatus';
import { SagaType } from '../../infrastructure/api/types/signup/SagaType';
import { SignupProgressResponse } from '../../infrastructure/api/types/signup/SignupProgressResponse';

export const pollSignupCompletion = function* (signupId: string, type: SagaType) {
  let numberOfTries = 0;

  while (numberOfTries < 120) {
    numberOfTries++;

    const {
      data: { status },
    }: SignupProgressResponse = yield call(SignupApi.signup.progress, { id: signupId, type });

    if (status === SagaStatus.Completed) {
      return;
    }

    yield delay(1000);
  }
};
